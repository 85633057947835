body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.warn-toast {
  background-color: #e65100;
}

input:invalid {
  border-color: #e65100;
}

input:aria-invalid {
  border-color: #e65100;
}
